import { RouteRecordRaw } from "vue-router";
import { userStore } from "@/composables/exchangeActions";
const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "MainLayout",
    component: () =>
      import(/* webpackChunkName: "MainLayout" */ "@/layouts/MainLayout.vue"),
    children: [
      {
        path: "",
        name: "home",
        component: () =>
          import(
            /* webpackChunkName: "HomePage" */ "../views/main/HomePageView.vue"
          ),
      },
      {
        path: "bot",
        name: "bot",
        component: () =>
          import(/* webpackChunkName: "Bot" */ "../views/main/BotView.vue"),
      },
      {
        path: "referral",
        name: "referralPage",
        component: () =>
          import(
            /* webpackChunkName: "Referral" */ "../views/main/ReferralView.vue"
          ),
      },
      {
        path: "about",
        name: "about",
        component: () =>
          import(
            /* webpackChunkName: "AboutUsSection" */ "../components/about/AboutUsSection.vue"
          ),
      },
      {
        path: "faq",
        name: "faq",
        component: () =>
          import(
            /* webpackChunkName: "FAQSection" */ "../components/about/FAQSection.vue"
          ),
      },
      {
        path: "user-agreement",
        name: "user-agreement",
        component: () =>
          import(
            /* webpackChunkName: "UserAgreementSection" */ "../components/about/UserAgreementSection.vue"
          ),
      },
      {
        path: "privacy",
        name: "privacy",
        component: () =>
          import(
            /* webpackChunkName: "PrivacySection" */ "../components/about/PrivacySection.vue"
          ),
      },
      {
        path: "disclaimer",
        name: "disclaimer",
        component: () =>
          import(
            /* webpackChunkName: "DisclaimerSection" */ "../components/about/DisclaimerSection.vue"
          ),
      },
      {
        path: "business",
        name: "business",
        component: () =>
          import(
            /* webpackChunkName: "business" */ "../views/main/BusinessView.vue"
          ),
      },
      {
        path: "exchange/:id?",
        name: "exchange",
        alias: ["exchanged"],
        component: () =>
          import(
            /* webpackChunkName: "exchange" */ "../views/main/ExchangeView.vue"
          ),
      },
      {
        path: "/profile",
        name: "profile",
        component: () =>
          import(
            /* webpackChunkName: "profile" */ "../layouts/profile/ProfileLayout.vue"
          ),
        children: [
          {
            path: "",
            redirect: { name: "history" },
          },
          {
            path: "history",
            name: "history",
            component: () =>
              import(
                /* webpackChunkName: "profile" */ "../views/profile/ProfileHistoryView.vue"
              ),
          },
          {
            path: "referral",
            name: "referral",
            component: () =>
              import(
                /* webpackChunkName: "profile" */ "../views/profile/ProfileReferralsView.vue"
              ),
          },
        ],
      },
      {
        path: "404",
        name: "not-found",
        component: () =>
          import(
            /* webpackChunkName: "not-found" */ "../views/main/NotFoundView.vue"
          ),
      },
      {
        path: "/:pathMatch(.*)*",
        redirect: "/404",
      },
    ],
  },
];

export default routes;
