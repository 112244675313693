import { createRouter, createWebHistory } from "vue-router";
import routes from "@/router/routes";

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    }
    if (to.hash) {
      return { el: to.hash, behavior: "smooth" };
    } else {
      document
        .getElementById("app")!
        .scrollTo({ left: 0, top: 0, behavior: "smooth" });
      document.documentElement.scrollTo({
        left: 0,
        top: 0,
        behavior: "smooth",
      });
    }
  },
});

export default router;
